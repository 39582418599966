
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

// --- CONST START ---
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import 'frontend/coreui-pro/coreui.min.js'
import 'frontend/coreui-pro/coreui-utilities.min.js'
// --- CONST END ---

Rails.start();
Turbolinks.start();
ActiveStorage.start();

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

const Shared = require('./shared.js');
const FrontendCommon = require('../frontend/common.js');
const FrontendParticipants = require('../frontend/participants.js');
window.atokyo3 = {
    shared: Shared,
    frontend_common: FrontendCommon,
    frontend_participants: FrontendParticipants
};

atokyo3.shared.load();
atokyo3.frontend_common.initLocale();
