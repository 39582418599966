
class FrontendParticipants {

    static new() {
        // amountの数値フィールドの最大値を書き換え
        $('.radio-capacity').on('change', (event) => {
            let max = $(event.target).data('groupMax');
            let $form = $(event.target).closest('.form-participants');
            let $field = $form.find('.field-participant-amount');
            $field.find('input').prop('max', max);
            let locale = $('.link-locale').data('currentlocale');
            // テキストの国際化
            if (locale === 'ja') {
                let text = "※最大__max__名まで";
                $field.find('.caution-text').text(text.replace('__max__', max));
            } else {
                let text = "*Up to __max__ person";
                $field.find('.caution-text').text(text.replace('__max__', max));
            }
            // 入力値が最大値を超えていた場合は、最大値に合わせる
            if ($field.find('input').val() > max) {
                $field.find('input').val(max);
            }
            return false;
        });
        return false;
    }

}
module.exports = FrontendParticipants
