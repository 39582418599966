

class FrontendCommon {
    static initLocale() {
        $(document).on('click', '.link-locale', (event) => {
            let $target = $(event.target);
            if($target.data('currentlocale') != $target.data('locale')){
                updateLocale($target.data('locale'));
            }
            return false;
        });

        function updateLocale (locale){
            let url = "/locale/__locale__"
            $.ajax(url.replace('__locale__', locale),{
                async: true
            }).done(function() {
                location.reload();
            }).fail(function(error) {
                alert(error.statusText);
            });
        }
    }
}
module.exports = FrontendCommon
